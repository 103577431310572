<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="$t('pageDescriptions.gdpr')"
    />

    <SelfMarketingCards />

    <DataTable
      :headers="headers"
      :items="data"
      :preloader="fetchingData"
      :watch="true"
      :trash="true"
      :exporting="true"
      @tableEvent="emittedTableEvent"
    ></DataTable>

    <v-dialog
      v-model="detailDialog"
      max-width="1500px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="detailDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title>Lead</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <LeadDetailOld
            v-if="leadData.version === 1"
            ref="lead_detail"
            :id="leadId"
            :leadData="leadData"
            :key="componentKey"
          ></LeadDetailOld>

          <LeadDetail
            v-else-if="leadData.version === 2"
            ref="lead_detail"
            :id="leadId"
            :leadData="leadData"
            :campaigns_id="leadCampaign.id"
            :key="componentKey"
          ></LeadDetail>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ConfirmationDialog
      :dialog="deleteDialog"
      :headline="$t('areYouSure')"
      :message="$t('deleteItemConfirmationText')"
      @reject="deleteDialog = false"
      @accept="deleteItem"
    />

    <ConfirmationDialog
      :dialog="exportDialog"
      :headline="$t('pleaseKeepInMind')"
      :message="$t('exportItemConfirmationText')"
      @reject="exportDialog = false"
      @accept="exportAsJson"
    />
  </v-container>
</template>

<script>
/* this.data enthält alle Leads */

import store from "@/store";
import axios from "axios";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import PageHeader from "@/components/ui/PageHeader";
import DataTable from "@/components/ui/DataTable";
import LeadDetailOld from "@/components/lead/old/LeadDetailOld";
import LeadDetail from "@/components/lead/manage/LeadDetail";
import ConfirmationDialog from "@/components/ui/ConfirmationDialog";
import SelfMarketingCards from "@/components/gdpr/SelfMarketingCards";

export default {
  name: "Gdpr",

  components: {
    PageHeader,
    DataTable,
    LeadDetailOld,
    LeadDetail,
    ConfirmationDialog,
    SelfMarketingCards,
  },

  data() {
    return {
      pageTitle: this.$t("gdpr"),
      pageDescription:
        "Hier findest du Möglichkeiten, Auskunft über personenbezogene Daten zu geben und Daten zu löschen.",
      fetchingData: false, // should be true when fetching data
      updatingData: false,
      companyID: store.getters["auth/current_sid"],
      newAdmin: store.getters["auth/newAdmin"],
      leadId: null,
      leadCampaign: null,
      leadIndex: null,
      leadData: [],
      data: [], // wrapper for fetched data
      headers: [],
      detailDialog: false,
      deleteDialog: false,
      exportDialog: false,
      componentKey: 0,
    };
  },

  props: [],

  created: function () {
    // allow to change company?
    this.$store.commit("companyChangeOption/SET_ALLOWED", true);

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    async deleteItem() {
      const that = this;

      let url = null;
      var dataArr = new URLSearchParams();
      dataArr.append("action", "anonymise");

      if (this.leadData.version === 2) {
        /* Und Anonymisieren könnten wir mit PUT companies/{CompanyID}/leads/{leadID}/anonymise */
        this.fetchinData = true;
        let response = await this.putRequest(
          `companies/${this.companyID}/leads/${this.leadId}/anonymise`
        );
        this.fetchinData = false;
        if (response.status === 200) {
          this.deleteDialog = false;
          this.data.splice(this.leadIndex, 1); // mutate
          /* CALL SNACKBAR */
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = this.$t("messageDeleted");
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      } else if (this.leadData.version === 1) {
        if (this.newAdmin !== "1") {
          this.updatingData = true;
          url = `${this.$manageApiUrl}companies/${this.companyID}/legacy-lead/${this.leadId}/anonymise`;

          axios
            .put(url, dataArr, {
              headers: {
                Token: store.getters["auth/token"],
                "Content-Type": "application/x-www-form-urlencoded",
              },
            })
            .then(function (response) {
              // handle success
              if (response.status === 200) {
                /* CALL SNACKBAR */
                that.$root.snackbarGlobal.snackbar = true;
                that.$root.snackbarGlobal.snackbarText =
                  that.$t("messageDeleted");
                that.$root.snackbarGlobal.snackbarColor = "primary";
                that.$root.snackbarGlobal.snackbarTimeout = 5000;

                that.deleteDialog = false;
                that.updatingData = true;
                that.getData();
              }
            })
            .catch(function () {
              /* start snackbar */
              that.$root.snackbarGlobal.snackbar = true;
              that.$root.snackbarGlobal.snackbarText =
                "Die API meldet einen Fehler!";
              that.$root.snackbarGlobal.errorMessage =
                "Bitte kontaktiere den FACEFORCE-Support.";
              that.$root.snackbarGlobal.snackbarColor = "red";
              that.$root.snackbarGlobal.snackbarTimeout = 10000;
            });
        } else {
          /* // neuer Admin ... darf keine alten Daten bearbeiten
          alert(this.$t("legacyBlockNote"));
          that.deleteDialog = false; */

          this.updatingData = true;
          url = `${this.$manageApiUrl}companies/${this.companyID}/legacy-lead/${this.leadId}/anonymise`;

          axios
            .put(url, dataArr, {
              headers: {
                Token: store.getters["auth/token"],
                "Content-Type": "application/x-www-form-urlencoded",
              },
            })
            .then(function (response) {
              // handle success
              if (response.status === 200) {
                /* CALL SNACKBAR */
                that.$root.snackbarGlobal.snackbar = true;
                that.$root.snackbarGlobal.snackbarText =
                  that.$t("messageDeleted");
                that.$root.snackbarGlobal.snackbarColor = "primary";
                that.$root.snackbarGlobal.snackbarTimeout = 5000;

                that.deleteDialog = false;
                that.updatingData = true;
                that.getData();
              }
            })
            .catch(function () {
              /* start snackbar */
              that.$root.snackbarGlobal.snackbar = true;
              that.$root.snackbarGlobal.snackbarText =
                "Die API meldet einen Fehler!";
              that.$root.snackbarGlobal.errorMessage =
                "Bitte kontaktiere den FACEFORCE-Support.";
              that.$root.snackbarGlobal.snackbarColor = "red";
              that.$root.snackbarGlobal.snackbarTimeout = 10000;
            });
        }
      }
    },

    async exportAsJson() {
      this.exportDialog = false;

      const that = this;
      let url = `${this.$manageApiUrl}companies/${this.companyID}/legacy-leads/${this.leadId}/export`;
      let token = store.getters["auth/manageToken"];

      if (this.leadData.version === 2) {
        url = `${this.$manageApiUrl}companies/${this.companyID}/leads/${this.leadId}/export`;
        token = store.getters["auth/manageToken"];
      }

      var request = new XMLHttpRequest();
      request.open("GET", url);
      request.setRequestHeader("Token", token);
      request.responseType = "arraybuffer";
      request.onload = function () {
        var blob = new Blob([this.response], {
          type: "text/csv;charset=utf-8;",
        });

        var exportedFilenmae =
          "export_lead_" + that.leadId + ".csv" || "export.csv";

        if (navigator.msSaveBlob) {
          // IE 10+

          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }

        /* CALL SNACKBAR */
        that.$root.snackbarGlobal.snackbar = true;
        that.$root.snackbarGlobal.snackbarText = that.$t("messageExported");
        that.$root.snackbarGlobal.snackbarColor = "primary";
        that.$root.snackbarGlobal.snackbarTimeout = 5000;
      };

      request.send();
    },

    async getData() {
      /* fetch data from api */
      this.fetchingData = true;
      let response = await this.getRequest(`companies/${this.companyID}/leads`); // await data from mixin
      this.fetchingData = false;
      this.data = response.data.data.leads;

      this.headers = [
        { text: this.$t("salutation"), value: "gender", width: "50px" },
        { text: this.$t("firstName"), value: "firstName" },
        { text: this.$t("lastName"), value: "lastName" },
        { text: this.$t("email"), value: "email" },
        { text: this.$t("phone"), value: "phone" },
        { text: this.$t("contactType"), value: "provider" }, // campaigns[0].type

        {
          text: this.$t("lastActivity"),
          value: "created",
        },

        { text: "", value: "actions", sortable: false, width: "30px" },
      ];
    },

    emittedTableEvent(obj) {
      this.leadId = obj.itemId;
      this.leadIndex = obj.itemIndex;

      if (obj.itemData) {
        this.leadCampaign = obj.itemData.campaign;
      }

      if (obj.action === "watchItem") {
        /* finde the real data in array
          vorher bin ich über INDEX gegangen, was sich aber als falsch herausgestellt hatte, da der INDEX auf die aktuelle Page der Tabelle bezogen war. */
        this.data.forEach((item) => {
          if (item.id === this.leadId) {
            this.leadData = item; // the data of that item
          }
        });

        this.detailDialog = true;
        this.componentKey += 1; // forces the component (here LeadDetail) to re-render -> https://michaelnthiessen.com/force-re-render/
      }

      if (obj.action === "deleteItem") {
        this.data.forEach((item) => {
          if (item.id === this.leadId) {
            this.leadData = item; // the data of that item
          }
        });

        this.deleteDialog = true;
      }

      if (obj.action === "exportItem") {
        this.data.forEach((item) => {
          if (item.id === this.leadId) {
            this.leadData = item; // the data of that item
          }
        });

        this.exportDialog = true;
      }
    },
  },

  mixins: [manageApi],
};
</script>
